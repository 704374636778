import styled from "styled-components";

export const StyledLoaderWrapperFullWidth = styled.div`
	width: 100%;
	margin-top: 0.5rem;

	display: flex;
	justify-content: center;
	align-items: center;
`;

// ** React Imports
import { forwardRef } from "react";
import { useHistory } from "react-router-dom";

// ** Redux & Store & Actions
import { connect, useSelector } from "react-redux";
import { logout } from "store/modules/auth";
import { setInitialState } from "store/reducer";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Paragraph from "components/typography/Paragraph";
import Block from "components/layout/Block";
import { ButtonPrimary } from "components/layout/Button/ButtonPrimary";
import Loader from "components/layout/Loader";
import NoContentWrapper from "../NoContentWrapper/NoContentWrapper";

// ** Styled Components
import { StyledWrapper, StyledContent, StyledLoaderWrapper } from "./styles";

// #################################################################

const LoadingContainer = forwardRef(
	(
		{
			ready,
			loading,
			hideLoader,
			error,
			response,
			onErrorButtonClick,
			onError,
			noContentOnCriteria,
			noContentText,
			noContentOnCriteriaText,
			children,
			empty,
			logout,
			setInitialState,
			noCenter,
			marginTopNoCenter = 2,
			noMinWidth = false,
			noMinHeight = false,
			noMinHeightWrapper = false,
			marginX = false,
			noScroll = false,
		},
		ref
	) => {
		const { t } = useTranslation("common");

		const isClient = useSelector((state) => state.global.isClient);

		const history = useHistory();

		const path = `${history?.location?.pathname}${history?.location?.search}`;

		let savedScrollsPositions = {};
		let currentPageScrollPosition = null;

		if (
			typeof sessionStorage !== "undefined" &&
			sessionStorage !== null &&
			sessionStorage
		) {
			const fromSessionStorage = sessionStorage.getItem(
				"savedScrollsPositionsFromDZ"
			);
			savedScrollsPositions = fromSessionStorage
				? JSON.parse(fromSessionStorage)
				: {};

			currentPageScrollPosition =
				typeof savedScrollsPositions[path] === "number"
					? savedScrollsPositions[path]
					: null;
		}

		const renderMessageBlock = (title, desctiption, btn) => {
			return (
				<NoContentWrapper flex column alignCenter textCenter>
					<Paragraph color="grey" size="1.15rem">
						{title}
					</Paragraph>
					{desctiption && (
						<Paragraph color="grey">{desctiption}</Paragraph>
					)}
					{btn || null}
				</NoContentWrapper>
			);
		};

		const renderMessageStandard = (text, desctiption) => {
			return (
				<Block
					flex
					column
					textCenter={noCenter ? false : true}
					alignCenter={noCenter ? false : true}
					my={noCenter ? marginTopNoCenter : 2}
					mt={noCenter ? marginTopNoCenter : 2}
					mx={marginX ? 4 : 0}
				>
					<Paragraph size="1rem" mb={noMinWidth ? 0 : 2}>
						{text}
					</Paragraph>
					{desctiption && (
						<Paragraph color="grey">{desctiption}</Paragraph>
					)}
				</Block>
			);
		};

		const againButton = (
			<ButtonPrimary
				label={t("common:errors.tryAgain")}
				onClick={onErrorButtonClick}
			/>
		);

		const renderContent = () => {
			const status =
				response?.status ||
				(error && error?.response?.status) ||
				response?.result?.status ||
				null;
			const notSuccess =
				error || (status !== null && !(status >= 200 && status < 300));

			if (typeof onError === "function" && notSuccess) {
				return onError(error);
			}

			if (notSuccess) {
				const errorResponse = error?.response || null;
				if (error && error.message === "timeout") {
					return renderMessageBlock(
						t("common:errors.commonErrorCatch"),
						t("common:errors.timeout"),
						againButton
					);
				}
				if (status === 404) {
					return renderMessageBlock(
						t("common:errors.notFoundTitle"),
						t("common:errors.notFound"),
						null
					);
				}
				if (status === 400) {
					return renderMessageBlock(
						t("common:errors.commonErrorCatch"),
						t("common:errors.default") +
							errorResponse?.data?.error ?? ""
					);
				}

				if (status === 401) {
					setInitialState();
					logout();
					return;
				}

				if (status === 409) {
					setInitialState();
					logout();
					return;
				}

				return renderMessageBlock(
					t("common:errors.commonErrorCatch"),
					t("common:errors.unexpected"),
					againButton
				);
			}

			if ((response && status === 204) || empty) {
				return renderMessageStandard(
					noContentText || t("common:errors.noResults"),
					noContentOnCriteria &&
						(noContentOnCriteriaText ||
							t("common:errors.noResultsOnCriteria"))
				);
			}
			return (ready === undefined || ready === true) && children;
		};
		// #################################################################

		return (
			<StyledWrapper
				ref={ref}
				noMinWidth={noMinWidth && !loading}
				noMinHeightWrapper={noMinHeightWrapper && !loading}
			>
				{loading && !hideLoader && isClient && (
					<StyledLoaderWrapper>
						<Loader />
						{(!currentPageScrollPosition ||
							history.action !== "POP") &&
							!noScroll &&
							window.scrollTo(0, 0)}
					</StyledLoaderWrapper>
				)}
				<StyledContent
					noMinHeight={noMinHeight && !loading}
					loading={!error && loading}
				>
					{renderContent()}
				</StyledContent>
			</StyledWrapper>
		);
	}
);

// #################################################################

LoadingContainer.displayName = "LoadingContainer";

export default connect(null, {
	logout,
	setInitialState,
})(LoadingContainer);

// #################################################################

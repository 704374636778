import { createElement } from "react";
import styled, { css } from "styled-components";

// #####################################################

const TextField = styled(({ password, ...props }) => {
	const newProps = { ...props };
	newProps.type = password ? "password" : "text";
	return createElement("input", { ...newProps });
})`
	--dz-comp-landing-search-input-border-color: var(--dz-ref-color-gray500);
	--dz-comp-landing-search-input-border-color-hover: var(
		--dz-ref-color-gray600
	);
	--dz-comp-landing-search-input-text-color: var(--dz-ref-color-gray800);

	display: block;
	padding: 0.375rem 1rem;
	width: 100%;
	font-size: 1rem;
	line-height: 1.75rem;
	background-image: none;
	background-color: var(--dz-sys-input-background-color);
	border: 1px solid;
	border-color: var(--dz-sys-input-border-color);
	border-radius: var(--dz-sys-input-border-radius);
	color: var(--dz-sys-text-color);
	-webkit-appearance: none;

	&:hover,
	&:focus-within,
	&:focus-visible {
		border-color: var(--dz-sys-input-border-color-hover);
		box-shadow: var(--dz-sys-input-shadow);
	}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.75;
		`}

	${({ $bold }) =>
		$bold &&
		css`
			font-weight: bold;
		`}

	${({ $addHeight }) =>
		$addHeight &&
		css`
			padding: 0 1rem;
			height: 35px;
		`}

	${({ $isLanding }) =>
		$isLanding &&
		css`
			color: var(--dz-comp-landing-search-input-text-color);
			background-color: transparent;
			border-color: var(--dz-comp-landing-search-input-border-color);

			padding: 0 1rem;
			height: 42px;

			&:hover,
			&:focus-within,
			&:focus-visible {
				border-color: var(
					--dz-comp-landing-search-input-border-color-hover
				);
				box-shadow: var(--dz-sys-input-shadow);
			}
		`}
`;

// #####################################################

export default TextField;

// #####################################################

import styled from "styled-components";

// #####################################################

const StyledTextarea = styled.textarea`
	padding: 0.375rem 1rem;
	border: 1px solid;
	width: 100%;
	resize: none;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.75rem;
	background-color: var(--dz-sys-input-background-color);
	border-radius: var(--dz-sys-input-border-radius);
	border-color: var(--dz-sys-input-border-color);
	color: var(--dz-sys-text-color);

	&:focus,
	&:focus-within,
	&:hover {
		border-color: var(--dz-sys-input-border-color-hover);
		box-shadow: var(--dz-sys-input-shadow);
		background-color: transparent;
	}
`;

// #####################################################

export default StyledTextarea;

// #####################################################

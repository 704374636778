// ** React Imports
import { memo, useCallback, useMemo } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";

// ** Custom Components
import Block from "components/layout/Block";
import Section from "components/layout/Section";
import DecorBox from "components/layout/DecorBox";

// ** Utils & Helpers
import pseudoFormat from "utils/pseudoFormat";

// #####################################################

const StatisticsSection = () => {
	const { t } = useTranslation(["common", "landing"]);

	const language = useSelector((state) => state.global.language);
	const usersOnline = useSelector((state) => state.global.statistics.online);
	const mediaTotal = useSelector(
		(state) => state.global.statisticsMain.media
	);
	const registeredUsers = useSelector(
		(state) => state.global.statisticsMain.users
	);

	const formatValue = useCallback(
		(value) => {
			return `${pseudoFormat(
				Math.floor((value / 1000000) * 10) / 10
			)} ${t("common:numbersShort:milion")}`;
		},
		[language]
	);

	const renderStats = useMemo(() => {
		return (
			<Block flex flexWrap>
				<DecorBox
					topText={t("siteStats.currently")}
					mainText={
						<Block style={{ minWidth: "98px" }}>
							<CountUp
								separator=" "
								enableScrollSpy
								scrollSpyOnce
								end={usersOnline > 0 ? usersOnline : 1}
								duration={2}
								start={1}
								update={usersOnline > 0 ? usersOnline : 1}
								preserveValue
							>
								{({ countUpRef }) => <span ref={countUpRef} />}
							</CountUp>
						</Block>
					}
					bottomText={t("siteStats.usersOnline")}
				/>
				<DecorBox
					topText={t("siteStats.moreThan")}
					mainText={
						<Block style={{ minWidth: "84px" }}>
							<CountUp
								separator=" "
								enableScrollSpy
								scrollSpyOnce
								end={
									registeredUsers > 0
										? registeredUsers
										: 100000
								}
								duration={2}
								formattingFn={formatValue}
								start={100000}
							>
								{({ countUpRef }) => <span ref={countUpRef} />}
							</CountUp>
						</Block>
					}
					bottomText={t("siteStats.registeredUsers")}
				/>
				<DecorBox
					topText={t("siteStats.moreThan")}
					mainText={
						<Block style={{ minWidth: "110px" }}>
							<CountUp
								separator=" "
								enableScrollSpy
								scrollSpyOnce
								end={mediaTotal > 0 ? mediaTotal : 100000}
								duration={2}
								formattingFn={formatValue}
								start={100000}
							>
								{({ countUpRef }) => <span ref={countUpRef} />}
							</CountUp>
						</Block>
					}
					bottomText={t("siteStats.addedMedia")}
				/>
			</Block>
		);
	}, [registeredUsers, registeredUsers, mediaTotal, language]);

	// #####################################################

	return <Section>{renderStats}</Section>;
};

// #####################################################

export default memo(StatisticsSection);

// #####################################################

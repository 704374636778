// ** React Imports
import { useState, useEffect, useContext, useRef } from "react";

// ** Redux & Store & Actions
import { connect } from "react-redux";
import { asyncConnect } from "redux-connect";
import {
	getEmail,
	getStatisticsMain,
	updateStatisticsSite,
} from "store/modules/global";
import { postSupportMessage } from "store/modules/mainPages";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

// ** Custom Components
import { CardWrapper } from "components/layout/Card/CardWrapper";
import { CardTitle } from "components/layout/Card/CardTitle";
import { CardsBlockWrapper } from "components/layout/Card/CardsBlockWrapper";
import { CardBody } from "components/layout/Card/CardBody";
import Heading from "components/typography/Heading";
import Paragraph from "components/typography/Paragraph";
import TextField from "components/layout/TextField";
import Text from "components/typography/Text";
import Link from "components/layout/Link";
import { ButtonPrimary } from "components/layout/Button/ButtonPrimary";
import Block from "components/layout/Block";
import Fieldset from "components/layout/Fieldset";
import Suspension from "components/suspension";
import EmptyBlock from "components/layout/Block/BlockEmpty";
import ScrollToTop from "components/global/ScrollToTop";

// ** Contact Components
import StyledTextarea from "./components/Textarea";
import StyledInput from "./components/Input";

// ** Custom Hooks
import useOpenGraphMetaTags from "hooks/useOpenGraphMetaTags";

// ** Utils & Helpers
import formatMessage from "utils/formatMessage";
import api from "helpers/ApiClient";

// ** Routes
import routes from "containers";

// ** Contexts
import authContext from "contexts/authContext";

// #####################################################

const Contact = ({
	getEmail,
	getStatisticsMain,
	emailApi,
	postSupportMessage,
}) => {
	const editor = useRef(null);

	const [email, setEmail] = useState(emailApi);
	const [message, setMessage] = useState("");
	const [fileName, setFileName] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [isMessageSent, setIsMessageSent] = useState(false);
	const [whileSending, setWhileSending] = useState(false);

	const isAuth = useContext(authContext);

	useEffect(() => {
		if (isAuth) {
			getEmail().then(({ result: { data } }) => setEmail(data.email));
		} else {
			getStatisticsMain();
		}
	}, []);

	const { t } = useTranslation(["common", "contact"]);

	const submitValue = () => {
		setWhileSending(true);
		if (isAuth) {
			api.get(`/system/token`).then(({ data: { token } }) => {
				postSupportMessage({
					token: token,
					email,
					message,
					file: selectedFile,
				})
					.then((res) => {
						if (res?.result?.status === 201) {
							editor.current.value = "";
							setSelectedFile(null);
							setFileName("");
							setIsMessageSent(true);
						}
					})
					.finally(() => {
						setWhileSending(false);
					});
			});
		} else {
			postSupportMessage({
				token: null,
				email,
				message,
				file: selectedFile,
			})
				.then((res) => {
					if (res?.result?.status === 201) {
						editor.current.value = "";
						setSelectedFile(null);
						setFileName("");
						setIsMessageSent(true);
					}
				})
				.finally(() => {
					setWhileSending(false);
				});
		}
	};

	const handleChange = (event) => {
		setMessage(event.target.value);
	};

	// ** Open Graph Meta Tags
	const ogMetaTags = useOpenGraphMetaTags();

	// #####################################################

	return (
		<>
			{/* Sekcja HEAD */}
			<Helmet title={t("contact:contact")}>{ogMetaTags}</Helmet>

			<Suspension />
			<ScrollToTop />

			<CardsBlockWrapper>
				{/* Główna zawartość strony */}
				<CardWrapper>
					<CardTitle>
						<Heading type="h2">{t("contact:contact")}</Heading>
					</CardTitle>

					<CardBody>
						{isMessageSent ? (
							<Paragraph>{t("contact:successMessage")}</Paragraph>
						) : (
							<>
								<Paragraph>
									<Text>
										{formatMessage(
											t(`contact:information`),
											{
												helpPageLink: (
													<Link
														underline
														to={routes.support.link}
														key="contact:information"
													>
														{t(
															`contact:helpPageText`
														)}
													</Link>
												),
											}
										)}
									</Text>
								</Paragraph>

								<Fieldset
									legend={t("contact:yourEmail")}
									legendBold={false}
								>
									<TextField
										type="text"
										onChange={({ target: { value } }) =>
											setEmail(value)
										}
										value={email}
										$addHeight
									/>
								</Fieldset>
								<Fieldset
									legend={t("contact:message")}
									legendBold={false}
								>
									<StyledTextarea
										ref={editor}
										onChange={handleChange}
										rows={5}
									></StyledTextarea>
								</Fieldset>
								<Fieldset
									legend={t("contact:file")}
									legendBold={false}
								>
									<StyledInput
										type="file"
										onChange={({
											target: { value, files },
										}) => {
											if (files && files[0]) {
												setFileName(value);
												setSelectedFile(files[0]);
											} else {
												setFileName("");
												setSelectedFile(null);
											}
										}}
										value={fileName}
									/>
								</Fieldset>
								<Block flex justifyEnd>
									<ButtonPrimary
										disabled={whileSending}
										onClick={submitValue}
									>
										{t("common:send")}
									</ButtonPrimary>
								</Block>
							</>
						)}
					</CardBody>
				</CardWrapper>

				<EmptyBlock />
			</CardsBlockWrapper>
		</>
	);
};

// #####################################################

const mapStatetoProps = ({
	global: {
		user: { email },
	},
}) => ({ emailApi: email });

export default connect(mapStatetoProps, {
	getEmail,
	getStatisticsMain,
	postSupportMessage,
})(
	asyncConnect([
		{
			key: "getStatisticsMainServer",
			promise: ({ store: { dispatch, getState } }) => {
				const state = getState();
				const isAuthFromServer = state.auth.isAuthFromServer;
				const isSSR = typeof window === "undefined";

				if (isSSR && !isAuthFromServer) {
					return dispatch(getStatisticsMain()).then((res) => {
						const { data, counters } = res?.result || {};
						dispatch(updateStatisticsSite(counters));
						return data ?? null;
					});
				}
			},
		},
	])(Contact)
);

import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Img = styled("img").attrs(
	({ src, alt, addBackground, realWidth, realHeight, ...props }) => ({
		src,
		alt,
		addBackground,
		height: realHeight,
		width: realWidth,
		...props,
	})
)`
	display: inline-block;

	${({ addBackground }) =>
		addBackground &&
		css`
			background-color: var(--dz-ref-color-gray100);
		`}

	${({ isBlurred }) =>
		isBlurred &&
		css`
			filter: blur(7px);
			filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
			-ms-filter: blur(7px);
			-webkit-filter: blur(7px);
		`}
`;

Img.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string,
};

Img.displayName = "Img";

export default Img;

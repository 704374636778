import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const RingPart = styled("div")`
	display: block;
	position: absolute;
	border-style: solid;
	border-radius: 50%;
	animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	box-sizing: border-box;
`;

const Ring = styled("div")`
	display: inline-block;
	position: relative;
	z-index: 999;
	width: ${({ size }) => size}px;
	height: ${({ size }) => size}px;

	${RingPart} {
		width: ${({ size }) => size - 2}px;
		height: ${({ size }) => size - 2}px;
	}

	div {
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		border: ${({ width }) => width}px solid
			${({ color, theme: { colors } }) => colors[color]};
		border-radius: 50%;
		animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: ${({ color, theme: { colors } }) => colors[color]}
			transparent transparent transparent;
		box-sizing: border-box;
	}

	${RingPart}:nth-child (1) {
		animation-delay: -0.45s;
	}
	${RingPart}:nth-child (2) {
		animation-delay: -0.3s;
	}
	${RingPart}:nth-child (3) {
		animation-delay: -0.15s;
	}
`;

const Loader = ({ ...props }) => {
	return (
		<Ring {...props}>
			<RingPart />
			<RingPart />
			<RingPart />
		</Ring>
	);
};

Loader.defaultProps = {
	color: "primary",
	size: 80,
	width: 8,
};

Loader.propTypes = {
	color: PropTypes.string,
	size: PropTypes.number,
	width: PropTypes.number,
};

export default Loader;

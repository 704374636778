// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const CardTitle = styled.div`
	padding: 0.75rem 1rem 0;

	${({ $withPaddingBottom }) =>
		$withPaddingBottom &&
		css`
			padding-bottom: 0.75rem;
		`}
`;

// #####################################################

// ** React
import { forwardRef } from "react";

// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import Block from "../Block";

// #####################################################

const StyledNoContentWrapper = styled(
	forwardRef(({ ...rest }, ref) => <Block ref={ref} {...rest} />)
)`
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
`;

// #####################################################

const NoContentWrapper = ({ children }) => {
	return <StyledNoContentWrapper>{children}</StyledNoContentWrapper>;
};

// #####################################################

export default NoContentWrapper;

// #####################################################

// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import Block from "../Block";

// #####################################################,

const StyledCardsBlockWrapper = styled((props) => <Block {...props} />)`
	gap: var(--size-grid);
	flex-direction: column;
	display: flex;
	flex: 1;
`;

// #####################################################

export const CardsBlockWrapper = ({ children, ...props }) => {
	return (
		<StyledCardsBlockWrapper {...props}>{children}</StyledCardsBlockWrapper>
	);
};

// #####################################################

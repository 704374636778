// ** Third Party Components
import styled, { css } from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledSectionContent = styled.div`
	min-height: 667px;
	height: 100vh;
	display: flex;
	flex-direction: column;
	padding-top: 1rem;

	@media (max-width: ${breakpoints.giant - 1}px) {
		min-height: 607px;
	}

	@media (max-width: ${breakpoints.desktop - 1}px) {
		padding-top: 0;
		min-height: 514px;
	}

	@media (max-width: ${breakpoints.phone - 1}px) {
		min-height: 497px;
	}

	${({ isKeyboardOpen }) =>
		isKeyboardOpen &&
		css`
			height: 100%;
		`}
`;

// #####################################################

const D = "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday".split(",");
const M =
	"January,February,March,April,May,June,July,August,September,October,November,December".split(
		","
	);

class DateTime {
	constructor(date) {
		this.setDate(date);
	}

	setDate = (date) => {
		if (!date) {
			this.date = new Date();
			return this;
		}
		const match = date.match(
			/(?<day>\d{2})-(?<month>\d{2})-(?<year>\d{4})/i
		);
		if (match) {
			const { year, month, day } = match.groups;
			this.date = new Date(year, month - 1, day);
		} else {
			this.date = new Date(date.replace(/-/g, "/"));
		}
		return this;
	};

	getDate = () => {
		return this.date;
	};

	getFullYear = () => this.date.getFullYear();
	getMonth = () => this.date.getMonth();
	getDay = () => this.date.getDate();

	modify = (mod) => {
		const firstChar = mod.charAt(0);
		if (firstChar !== "-" && firstChar !== "+") {
			throw Error(
				"first character od modify string must be a minus `-` or plus `+`"
			);
		}
		const match = mod.match(
			/(\+|-)([0-9]+) (milisecond|second|minute|hour|day|week|month|year)/i
		);
		if (match.length) {
			match.shift();
			const [modx, value, type] = match;
			const interval = Number(modx === "+" ? value : value * -1);
			switch (type) {
				case "milisecond":
					break;
				case "second":
					break;
				case "minute":
					break;
				case "hour":
					break;
				case "day":
					this.date.setDate(this.date.getDate() + interval);
					break;
				case "week":
					break;
				case "month":
					this.date.setMonth(this.date.getMonth() + interval);
					break;
				case "year":
					break;
				default:
					break;
			}
		}
		return this;
	};

	format = (format) => {
		const defaultFormat = "DD.MM.YYYY HH:mm";
		let preFormat = "";
		switch (format) {
			case "DATE_ATOM":
				preFormat = "YYYY-MM-DDTHH:mm:ss+00:00";
				break;
			default:
				preFormat = format;
				break;
		}
		const finalFormat = preFormat || defaultFormat;
		const { date } = this;
		function getRet(str, firstChar) {
			// if (str.charAt(0) === "'") {
			// 	return (firstChar = 0) || str.slice(1, -1).replace(/''/g, "'");
			// }
			if (str === "a") {
				return date.getHours() < 12 ? "am" : "pm";
			}
			if (str === "A") {
				return date.getHours() < 12 ? "AM" : "PM";
			}
			if (str === "Z") {
				return `${`+${-date.getTimezoneOffset() / 60}`
					.replace(/^\D?(\D)/, "$1")
					.replace(/^(.)(.)$/, "$10$2")}00`;
			}
			if (firstChar === "S") {
				return date.getMilliseconds();
			}
			if (firstChar === "s") {
				return date.getSeconds();
			}
			if (firstChar === "H") {
				return date.getHours();
			}
			if (firstChar === "h") {
				return date.getHours() % 12 || 12;
			}
			if (firstChar === "D" && str.length > 2) {
				return D[date.getDay()].slice(0, str.length > 3 ? 9 : 3);
			}
			if (firstChar === "D") {
				return date.getDate();
			}
			if (firstChar === "M" && str.length > 2) {
				return M[date.getMonth()].slice(0, str.length > 3 ? 9 : 3);
			}
			if (firstChar === "m") {
				return date.getMinutes();
			}
			if (firstChar === "M") {
				return date.getMonth() + 1;
			}
			return `${date.getFullYear()}`.slice(-str.length);
		}
		return finalFormat.replace(
			/a|A|Z|S(SS)?|ss?|mm?|HH?|hh?|D{1,4}|M{1,4}|YY(YY)?|'([^']|'')*'/g,
			(str) => {
				const firstChar = str.charAt(0);
				const ret = getRet(str, firstChar);
				return firstChar &&
					str.length < 4 &&
					`${ret}`.length < str.length
					? `00${ret}`.slice(-str.length)
					: ret;
			}
		);
	};

	isValid = () => {
		// console.log("isval", this.date);
		// eslint-disable-next-line eqeqeq
		return this.date != "Invalid Date";
	};

	from(value = {}) {
		this.date = new Date(
			value.year || this.date.getFullYear(),
			value.month || this.date.getMonth(),
			value.day || this.date.getDate(),
			value.hours || this.date.getHours(),
			value.minutes || this.date.getMinutes(),
			value.seconds || this.date.getSeconds(),
			value.ms || value.milliseconds || this.date.getMilliseconds()
		);

		return this;
	}

	clone() {
		const result = new DateTime();

		result.date = new Date(
			this.date.getFullYear(),
			this.date.getMonth(),
			this.date.getDate(),
			this.date.getHours(),
			this.date.getMinutes(),
			this.date.getSeconds(),
			this.date.getMilliseconds()
		);

		return result;
	}

	static from(value) {
		return new DateTime().from(value);
	}
}

export default DateTime;

"use client";

// ** React Imports
import { memo } from "react";

// ** Third Party Components
import { ErrorBoundary } from "react-error-boundary";
import loadable from "@loadable/component";

// ** Components Imports
const FallbackContent = loadable(() => import("./components/FallbackContent"), {
	ssr: false,
});

// ** Utils & Helpers
import api from "helpers/ApiClient";

// #####################################################

const SEND_API_ERROR = true;
const FALLBACK_RELOAD_DELAY_APPLICATION = 10 * 1000;
const FALLBACK_RELOAD_DELAY_MISSING_CHUNK = 3 * 1000;

// #####################################################

const Fallback = ({ children }) => {
	const isDev = process.env.NODE_ENV === "development";

	const isAfterRefresh = window.location.hash === "#refresh";

	const reloadPage = () => {
		if (typeof window !== "undefined") {
			if (!isAfterRefresh) {
				window.location = window.location + "#refresh";
			}

			window.location.reload();
		}
	};

	const fallbackRender = ({ error }) => {
		const missingChunkType =
			error.type === "missing" ||
			error.name === "ChunkLoadError" ||
			error.code == "CSS_CHUNK_LOAD_FAILED";
		return (
			<FallbackContent
				missingChunkType={missingChunkType}
				reloadPage={reloadPage}
			/>
		);
	};

	const handleError = (error) => {
		if (
			error.type === "missing" ||
			error.name === "ChunkLoadError" ||
			error.code == "CSS_CHUNK_LOAD_FAILED"
		) {
			setTimeout(() => {
				if (typeof window !== "undefined") {
					window.location.reload();
				}
			}, FALLBACK_RELOAD_DELAY_MISSING_CHUNK);
		} else {
			if (!isDev) {
				if (SEND_API_ERROR && !isAfterRefresh) {
					const data = {
						route: window?.location?.href ?? "FAILED_TO_GET_ROUTE",
						error: error?.message ?? "UNKNOWN ERROR",
						error_stack: error?.stack?.toString() ?? "",
						from: "Fallback",
						error_full: JSON.stringify(error),
					};
					api.post("/system/react-error", data);
				}

				setTimeout(() => {
					reloadPage();
				}, FALLBACK_RELOAD_DELAY_APPLICATION);
			}
		}
	};

	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={handleError}>
			{children}
		</ErrorBoundary>
	);
};

export default memo(Fallback);

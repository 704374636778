import { useEffect, useState } from "react";

const useDetectKeyboardOpen = (minKeyboardHeight = 200) => {
	const [isKeyboardOpen, setIsKeyboardOpen] = useState(null);
	const [visualViewportHeight, setVisualViewportHeight] = useState(0);

	useEffect(() => {
		const listener = ({ target: { height } }) => {
			const newState = window.screen.height - minKeyboardHeight > height;

			if (isKeyboardOpen != newState) {
				setIsKeyboardOpen(newState);
			}

			if (visualViewportHeight != height) {
				setVisualViewportHeight(height);
			}
		};

		if (typeof visualViewport != "undefined") {
			window.visualViewport.addEventListener("resize", listener);
		}
		return () => {
			if (typeof visualViewport != "undefined") {
				window.visualViewport.removeEventListener("resize", listener);
			}
		};
	}, []);

	return { isKeyboardOpen, visualViewportHeight };
};

export default useDetectKeyboardOpen;

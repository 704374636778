// ** React Imports
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { renderRoutes } from "react-router-config";

// ** Redux & Store & Actions
import { connect, useSelector } from "react-redux";
import { getUser, setIsClient } from "store/modules/global";

// ** Third Party Components
import { Helmet } from "react-helmet";
import { window } from "ssr-window";
import { ThemeProvider } from "styled-components";
import cookie from "js-cookie";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

// ** Contexts
import { AuthProvider } from "contexts/authContext";
import { DeviceTypeProvider } from "contexts/deviceTypeContext";

// ** Config
import config from "configuration";

// ** Custom Hooks
import useBreakpoint from "hooks/useBreakpoint";
import useMobileDetect from "hooks/useMobileDetectHook";
import useDarkMode from "hooks/useDarkMode";
import usePWAInstalable from "hooks/usePWAInstalable";
import useWindowScrollbarWidth from "hooks/useWindowScrollbarWidth";

// ** Utils & Helpers
import { convertToMilion, floor } from "utils/math";
import api from "helpers/ApiClient";
import checkTokenExist from "helpers/checkTokenExist";
import getTokenInfo from "helpers/getTokenInfo";

// ** Styles Import
import darkTheme from "styles/darkTheme";
import lightTheme from "styles/lightTheme";
import landingTheme from "styles/landingTheme";
import { StyledAppWrapper } from "./styles/AppWrapper.styled";

// #####################################################

const WrapperComponent = ({
	route,
	language,
	userLoaded,
	getUser,
	setIsClient,
	isAuthFromServer,
}) => {
	const { t } = useTranslation(["common"]);

	const cookieAuth = cookie.get(config.auth.cookieName);

	let isAuth = false;
	if (typeof window === "undefined") {
		isAuth = isAuthFromServer || false;
	} else if (cookieAuth) {
		isAuth = checkTokenExist(cookieAuth) || false;
	}

	// ** Tryb Ciemny
	const { isDarkMode } = useDarkMode();

	// ** check PWA is Installable
	usePWAInstalable();

	// **
	const location = useLocation();
	const isLanding = location.pathname === "/";

	// ** userAgent Pobrany z Requestu Użytkownika Przez Serwer
	const userAgentFromServerState = useSelector(
		(state) => state.global.userAgent.ua
	);

	// ** Hook useMobileDetect
	const mobileDetect = useMobileDetect(userAgentFromServerState);
	const verticalScrollbarWidth = useWindowScrollbarWidth();

	const isDesktopFromServer = mobileDetect.isDesktop();
	const isMobileFromServer = mobileDetect.isMobile();
	const isDesktopFromClient = useBreakpoint("desktop", "min");
	const isGiantFromClient = useBreakpoint("giant", "min");
	const isTabletFromClient = useBreakpoint("desktop", "max");
	const isPhoneFromClient = useBreakpoint("phone", "max");
	const [prevCookieAuth, setPrevCookieAuth] = useState(cookieAuth || null);

	const [deviceType, setDeviceType] = useState({
		isGiant: isDesktopFromServer,
		isDesktop: isDesktopFromServer,
		isTablet: isMobileFromServer,
		isPhone: isMobileFromServer,
	});

	const isClient = useSelector((state) => state.global.isClient);

	// ** Statystyki - Liczba użytkowników
	const usersTotalCount = useSelector(
		(state) => state.global.statisticsMain.users
	);

	useEffect(() => {
		setDeviceType({
			isGiant: isGiantFromClient,
			isDesktop: isDesktopFromClient,
			isTablet: isTabletFromClient,
			isPhone: isPhoneFromClient,
		});
	}, [
		isGiantFromClient,
		isDesktopFromClient,
		isTabletFromClient,
		isPhoneFromClient,
	]);

	useEffect(() => {
		if (!isClient) {
			setIsClient(true);
		}
	}, [isAuth]);

	useEffect(() => {
		const currentApiLanguage = api.getLanguage();
		if (currentApiLanguage !== language) {
			api.modifyLanguageHeader(language);
		}
	}, [language]);

	useEffect(() => {
		if (isAuth && !userLoaded) {
			getUser();
		}

		// ** Dodanie zdarzenia niestandardowego - 'openInPWA' w przypadku gdy strona została otwara w aplikacji PWA
		const mqStandAlone = "(display-mode: standalone)";
		if (
			navigator.standalone ||
			(window?.matchMedia && window.matchMedia(mqStandAlone).matches)
		) {
			if (
				window?.dataLayer &&
				typeof window.dataLayer.push === "function"
			) {
				window.dataLayer.push({
					event: "openInPWA",
				});
			}
		}
	}, [isAuth]);

	useEffect(() => {
		if (
			cookieAuth !== prevCookieAuth &&
			prevCookieAuth &&
			cookieAuth &&
			userLoaded &&
			isAuth
		) {
			const info = getTokenInfo(cookieAuth);
			const infoPrev = getTokenInfo(prevCookieAuth);

			if (info?.id !== infoPrev?.id) {
				getUser();
			}
		}
		setPrevCookieAuth(cookieAuth);
	}, [cookieAuth, prevCookieAuth, isAuth, userLoaded]);

	// **
	const memonizedTheme = useMemo(() => {
		if (isLanding && !isAuth && isDarkMode) {
			return { ...landingTheme, verticalScrollbarWidth };
		} else if (isDarkMode) {
			return { ...darkTheme, verticalScrollbarWidth };
		} else {
			return { ...lightTheme, verticalScrollbarWidth };
		}
	}, [isDarkMode, isLanding, isAuth, verticalScrollbarWidth]);

	const titleTemplate = useMemo(() => {
		const titleTemplate = t("common:titleTemplate");
		return `%s - ${titleTemplate}`;
	}, [language]);

	const convertedValue = useMemo(() => {
		return floor(convertToMilion(usersTotalCount))
			? floor(convertToMilion(usersTotalCount))
					.toFixed(1)
					.split(".")
					.join(",")
					.toLocaleString("pl-PL")
			: "1,0";
	}, [usersTotalCount]);

	const metaDescription = `Umawiaj się na sex spotkania albo znajdź partnera na dłużej! Ponad ${convertedValue} mln realnych osób na Datezone. Wejdź i sprawdź za darmo!`;

	// ** A/B Tests !!!
	// ** Przy dodawaniu wszystkich badań gdzie warunek zależy od ID użytkownika zadbać, aby dodać sprawdzenie:
	// ** "if (isAuth && userId)" - ponieważ przez jakiś czas po zalogowaniu userId === null co może wpływać na
	// ** ilość poszczególnych zdarzeń!!!

	// #####################################################

	return (
		<ThemeProvider theme={memonizedTheme}>
			<AuthProvider value={isAuth}>
				<DeviceTypeProvider value={deviceType}>
					<StyledAppWrapper
						id="app"
						className={isDarkMode ? "isDarkMode" : "isLightMode"}
					>
						<Helmet titleTemplate={titleTemplate}>
							<meta
								name="description"
								content={metaDescription}
							/>
							<link
								rel="icon"
								type="image/png"
								sizes="16x16"
								href="/ico/16x16.png"
							/>
							<link
								rel="icon"
								type="image/png"
								sizes="32x32"
								href="/ico/32x32.png"
							/>
							<link
								rel="icon"
								type="image/png"
								sizes="192x192"
								href="/ico/192x192.png"
							/>
							<link rel="manifest" href="/datezone.webmanifest" />
						</Helmet>

						{renderRoutes(route.routes)}
					</StyledAppWrapper>

					<ToastContainer />
				</DeviceTypeProvider>
			</AuthProvider>
		</ThemeProvider>
	);
};

// #####################################################

const mapStateToProps = ({
	global: {
		user: { loaded },
		language,
	},
	auth: { isAuthFromServer },
}) => ({
	language,
	userLoaded: loaded,
	isAuthFromServer,
});

export default connect(mapStateToProps, {
	getUser,
	setIsClient,
})(WrapperComponent);

// #####################################################

// ** React Imports
import { memo } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import loadable from "@loadable/component";
import withHydrationOnDemand from "react-hydration-on-demand";

// ** Custom Components
import Heading from "components/typography/Heading";
import Paragraph from "components/typography/Paragraph";
import Block from "components/layout/Block";
import Text from "components/typography/Text";

const WelcomeSlider = loadable(() => import("./WelcomeSlider"), {
	ssr: false,
});
const WelcomeSliderWithHydrationOnDemand = withHydrationOnDemand({
	on: ["idle"],
	onBefore: WelcomeSlider.load,
})(WelcomeSlider);

// #####################################################

const Welcome = ({ centerScreenshot }) => {
	const { t } = useTranslation(["landing"]);

	const isDarkMode = useSelector((state) => state.global.darkMode.darkMode);

	// #####################################################

	return (
		<Block flex column fullWidth>
			<Block pr={5} pt={10}>
				<Text color={"whiteAlways"}>
					<Heading bold type="h1" size="2rem">
						{t("landing:header")}
					</Heading>
					<Paragraph size="1.5rem" line="1.5rem" bold>
						{t("landing:subheader")}
					</Paragraph>
					<Paragraph size="1.5rem" line="1.5rem" bold mb={10}>
						{t("landing:desc")} {t("landing:descCta")}
					</Paragraph>
				</Text>
			</Block>
			<Block
				fullWidth
				justifyCenter
				alignCenter
				textCenter={centerScreenshot}
			>
				<WelcomeSliderWithHydrationOnDemand />
			</Block>
		</Block>
	);
};

// #####################################################

export default memo(Welcome);

export const initialQuery = {
	sort: "all",
	page: 1,
	sex: "woman-man-couple-transsexual",
	sex_couple: "",
	sex_couple_partner: "",
	age_from: "",
	age_to: "",
	distance: 50,
	who_search: "",
	purpose: "",
	only: "",
	who_search_age: "",
	language_search: "",
	height_from: "",
	height_to: "",
	figure_type: "",
	zodiac: "",
	hair: "",
	relationship: "",
	kids: "",
	drinking: "",
	smoking: "",
	education: "",
	preferences: "",
	interests: "",
	nick: "",
};

// ** React Imports
import { createElement } from "react";

// ** Third Party Components
import styled, { css } from "styled-components";

// #################################################################

export const StyledWrapper = styled(
	({ noMinWidth, noMinHeightWrapper, ...props }) =>
		// Jest błąd w nazwie parametru - powinien się nazywać "noMinHeight", a nie "noMinWidth"
		// Nie zmieniam, bo jest ten parametr jest już używany w wielu miejscach
		createElement("div", props)
)`
	position: relative;
	min-height: 100px;

	${({ noMinWidth }) =>
		noMinWidth &&
		css`
			min-height: initial;
		`}

	${({ noMinHeightWrapper }) =>
		noMinHeightWrapper &&
		css`
			min-height: initial;
		`}
`;

// #################################################################

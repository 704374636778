// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import Block from "../Block";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const CardBody = styled(
	({ noPaddingXmobile, isMediaPostCard, ...rest }) => <Block {...rest} />
)`
	display: flex;
	flex-direction: column;
	padding: 0.75rem 1rem;

	${({ noPaddingXmobile }) =>
		noPaddingXmobile &&
		css`
			@media (max-width: ${breakpoints.phone - 1}px) {
				padding-left: 0;
				padding-right: 0;
			}
		`}

	${({ $noPaddingTop }) =>
		$noPaddingTop &&
		css`
			padding-top: 0;
		`}

	${({ isMediaPostCard }) =>
		isMediaPostCard &&
		css`
			@media (max-width: ${breakpoints.phone - 1}px) {
				padding: 0.75rem 0 0.75rem;
			}
		`}

	${({ $isUsersListingCard }) =>
		$isUsersListingCard &&
		css`
			padding: 1rem 1rem;
		`}
`;
// #####################################################

import loadable from "@loadable/component";
import { useHistory } from "react-router-dom";

const List = loadable(() => import("./List/List"));
const Article = loadable(() => import("./Article/Article"));

export default [
	{
		key: "newsList",
		component: List,
		path: "/news/:page([0-9]+)?",
		exact: true,
	},
	{
		key: "newsArticle",
		component: Article,
		path: "/news/:id([0-9]+)/:slug([a-z0-9-]+)",
		exact: true,
	},
	{
		key: "news404",
		component: () => {
			const history = useHistory();
			history.push("/error404");
		},
		path: "/news/*",
		exact: false,
	},
];

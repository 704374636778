// ** React
import { forwardRef } from "react";

// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import Block from "../Block";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const CardWrapper = styled(
	forwardRef(({ ...rest }, ref) => <Block ref={ref} {...rest} />)
)`
	display: flex;
	flex-direction: column;
	background-color: var(--dz-sys-card-background-color);
	max-width: calc(100vw);
	min-width: 200px;
	border-radius: var(--dz-sys-card-border-radius);
	box-shadow: var(--dz-sys-card-shadow);

	@media (max-width: ${breakpoints.phone - 1}px) {
		border-radius: 0;
	}

	${({ $isMasonry }) =>
		$isMasonry &&
		css`
			border-radius: 0;
		`}
`;

// #####################################################

// ** React Imports
import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

// ** Redux & Store & Actions
import { useSelector, useDispatch } from "react-redux";
import { asyncConnect } from "redux-connect";
import { updateStatisticsSite } from "store/modules/global";
import { getNewsJson } from "store/modules/mainPages";

// ** Third Party Components
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import md5 from "md5";
import { Helmet } from "react-helmet";

// ** Custom Components
import LoadingContainer from "components/layout/LoadingContainer";
import Text from "components/typography/Text";
import Paragraph from "components/typography/Paragraph";
import Link from "components/layout/Link";
import Pagination from "components/layout/Pagination";

// ** Utils & Helpers
import DateTime from "utils/datetime";
import safeGeneratePath from "utils/generatePath";

// ** Config
import configGlobal from "configuration";

// ** Routes
import routes from "../index";

// #####################################################

const NewsList = styled("ul")`
	list-style: none;
	padding: 0;
	margin: 0;
`;

const NewsListArticle = styled("li")`
	margin-bottom: 0.75rem;
`;

// #####################################################

const List = ({
	route: { path },
	match: {
		params: { page },
	},
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation(["pages"]);
	const language = useSelector((state) => state.global.language);
	const history = useHistory();

	const currentPage = Number(page || 1);

	const md5HashFromState = useSelector((state) => state.mainPages.news.hash);
	const newsListData = useSelector((state) => state.mainPages.news.data);
	const loading = useSelector((state) => state.mainPages.news.loading);
	const pagination = useSelector((state) => state.mainPages.news.pagination);

	const newsArticleRoute = routes.find((value) => {
		if (value.key === "newsArticle") {
			return value;
		}
		return null;
	});

	// ** Hook useMemo - generujący 'newsList' jednie po zmianie 'data'
	const newsList = useMemo(
		() =>
			newsListData.map((singleNews) => {
				const { id, name_url } = singleNews;
				return {
					...singleNews,
					link: safeGeneratePath(newsArticleRoute.path, {
						id,
						slug: name_url,
					}),
					meta: {
						datetimeForTag: new DateTime(singleNews.date).format(),
					},
				};
			}),
		[newsListData, language]
	);

	// ** Efekt pobierajacy dane strony w przypadku gdy nie zostały
	// ** załadowane wcześniej (np. przez SSR) lub gdy zmienił się język strony
	useEffect(() => {
		const actualMd5Hash = md5(JSON.stringify({ language, page }));
		if (actualMd5Hash !== md5HashFromState) {
			dispatch(
				getNewsJson({
					page: currentPage,
					language,
					hash: actualMd5Hash,
				})
			).then((data) => {
				if (data?.result?.status === 204) history.push("/error404");
			});
		}
	}, [language, currentPage]);

	const canonicalLink = useCallback(() => {
		return (
			<link rel="canonical" href={`https://${configGlobal.host}/news`} />
		);
	}, []);

	const renderListing = () => (
		<NewsList>
			{newsList.map((article) => {
				return (
					<NewsListArticle key={article.id}>
						<Text bold>
							<Link
								underline
								to={{
									pathname: article.link,
									state: { articleTitle: article.name },
								}}
								bold
								color="primary"
							>
								{article.name}
							</Link>
						</Text>
						<br />
						<Text color="grey" small>
							{t("common:added")}:{" "}
							<time dateTime={article?.meta?.datetimeForTag}>
								{new DateTime(article.date).format()}
							</time>
						</Text>
						<Paragraph textBreak mt={2}>
							{article.description}
						</Paragraph>
					</NewsListArticle>
				);
			})}
		</NewsList>
	);

	// #####################################################

	return (
		<>
			{/* Sekcja HEAD */}
			<Helmet>{canonicalLink()}</Helmet>

			{/* Główna zawartość strony */}
			<LoadingContainer loading={loading}>
				{renderListing()}
				<Pagination
					total={pagination.totalpages || 1}
					current={currentPage}
					path={path}
				/>
			</LoadingContainer>
		</>
	);
};

// #####################################################

export default asyncConnect([
	{
		key: "articleListFromSSR",
		promise: ({ store: { dispatch, getState }, match }) => {
			const { params } = match || {};
			const { page } = params || {};

			const {
				auth: { isAuthFromServer },
				global: { language },
			} = getState();

			const isSSR = typeof window === "undefined";

			const listMd5Hash = md5(JSON.stringify({ language, page }));

			if (isSSR && !isAuthFromServer) {
				return dispatch(
					getNewsJson({ page, language, hash: listMd5Hash })
				).then((res) => {
					const { data, counters } = res?.result || {};
					dispatch(updateStatisticsSite(counters));
					return data ?? null;
				});
			}
		},
	},
])(List);

import { generatePath } from "react-router-dom";

export default (route, params) => {
	try {
		return generatePath(route, params);
	} catch (error) {
		// NOTE(Przemek): Ciężko jest znaleźć błąd, kiedy jest ignorowany (proponuję użycie chociażby console.warn?)
		return null;
	}
};

// ** React Imports
import { useContext } from "react";

// ** Third Party Components
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

// ** Custom Components
import Block from "components/layout/Block";
import Text from "components/typography/Text";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";

// #####################################################

const FieldsetStyled = styled("fieldset")`
	display: flex;
	padding: 0.5rem 0;

	${({ noPadding }) =>
		noPadding &&
		css`
			padding: 0;
		`}

	${({ noPaddingBottom }) =>
		noPaddingBottom &&
		css`
			padding-bottom: 0;
		`}

		

	${({ isPhone }) =>
		isPhone
			? css`
					flex-direction: column;

					${({ paddingPhone }) =>
						paddingPhone &&
						css`
							padding: ${paddingPhone};
						`}
			  `
			: css`
					align-items: center;
			  `}
`;

// #####################################################

const Fieldset = ({
	legend,
	legendBold,
	children,
	alignStart,
	column,
	paddingPhone,
	noPadding,
	noPaddingBottom,
}) => {
	const { isPhone } = useContext(deviceTypeContext);

	return (
		<FieldsetStyled
			noPadding={noPadding}
			paddingPhone={paddingPhone}
			noPaddingBottom={noPaddingBottom}
			isPhone={isPhone || column}
			style={alignStart && { alignItems: "flex-start" }}
		>
			{typeof title === "function" && !isPhone ? (
				legend()
			) : (
				<Block
					width={isPhone ? "" : 190}
					flex
					alignCenter /* as="legend" */
					style={isPhone ? null : { minWidth: "190px" }}
				>
					{legend && <Text bold={legendBold}>{legend}:</Text>}
				</Block>
			)}
			{children}
		</FieldsetStyled>
	);
};

// #####################################################

Fieldset.defaultProps = {
	legendBold: true,
};

Fieldset.propTypes = {
	legendBold: PropTypes.bool,
};

export default Fieldset;

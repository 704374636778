// ** Third Party Components
import axios from "axios";

import { API_REACT_URL } from "config";

// #####################################################

const api = axios.create({
	baseURL: API_REACT_URL,
	crossdomain: false,
});

api.interceptors.response.use(
	async (response) => {
		const { status, data } = response || {};

		if (status === 204 && data === "") {
			return {
				...response,
				data: null,
			};
		}

		if (data?.response) {
			return {
				...response,
				data: data.response,
			};
		}

		return {
			...response,
			data,
		};
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default api;

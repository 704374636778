// ** Third Party Components
import styled from "styled-components";

// #################################################################

export const StyledLoaderWrapper = styled("div")`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	// height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
`;

// #################################################################

// ** React Imports
import { createElement } from "react";

// ** Third Party Components
import styled, { css } from "styled-components";

// #################################################################

export const StyledContent = styled(({ loading, noMinHeight, ...props }) =>
	createElement("div", props)
)`
	display: inline-block;
	width: 100%;
	opacity: 1;
	transition: opacity 50ms;
	${({ loading }) =>
		loading &&
		css`
			pointer-events: none;
			opacity: 0;
		`}

	${({ noMinHeight }) =>
		noMinHeight &&
		css`
			display: block;
		`}
`;

// #################################################################

StyledContent.displayName = "LoadingContainerContent";

// #################################################################

import { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isFunction } from "utils/isFunction";

// ** Styles Import
import { breakpoints } from "styles/grid";

const Message = styled.div`
	display: flex;
	flex-direction: column;
`;

const Container = styled.div`
	display: flex;
	background: ${({ theme: { colors } }) => colors.red};
	border: 1px solid ${({ theme: { colors } }) => colors.red};
	border-radius: ${({ theme: { borderRadius } }) => borderRadius.card};
	color: white;
	margin-bottom: var(--size-grid);

	@media (max-width: ${breakpoints.phone - 1}px) {
		border-radius: 0;
	}

	${({ full }) =>
		full &&
		css`
			width: 100%;
			flex: 1 0 100%;
		`}

	${Message} {
		flex: 1 1 auto;
		padding: 1rem;
	}

	button {
		align-self: flex-start;
		display: flex;
		padding: 1rem;
		opacity: 0.7;
		transition: 0.3s ease;
		color: white;
	}

	button svg {
		fill: white;
	}

	button:hover {
		opacity: 1;
	}
`;

function CountDown({
	duration,
	onExpired,
	onUpdate,
	updateOnFirstRender,
	onMounted,
	onUnmounted,
	full,
	children,
}) {
	const mounted = useRef(false);
	const timeout = useRef(null);

	const onTick = () => {
		const start = Date.now();
		const reminder = Math.floor(duration - 1);

		clearTimeout(timeout.current);

		if (!mounted.current) {
			return;
		}

		if (reminder <= 0) {
			if (isFunction(onExpired)) {
				onExpired();
			}

			return;
		}

		duration = reminder;

		if (isFunction(onUpdate)) {
			onUpdate(reminder);
		}

		timeout.current = setTimeout(onTick, 1000 - (Date.now() - start));
	};

	useEffect(() => {
		if (duration <= 0) {
			clearTimeout(timeout.current);
			return null;
		}

		mounted.current = true;
		timeout.current = setTimeout(onTick, Date.now() % 1000);

		if (updateOnFirstRender) {
			onTick();
		}

		if (isFunction(onMounted)) {
			onMounted();
		}

		return () => {
			mounted.current = false;
			clearTimeout(timeout.current);

			if (isFunction(onUnmounted)) {
				onUnmounted();
			}
		};
	}, [duration]);

	return <Container full={full}>{children}</Container>;
}

export default function Suspension(props) {
	const { t } = useTranslation("suspensions");

	const suspension = useSelector((state) => state.user?.suspension);
	const [isDismissed, setIsDismissed] = useState(false);

	const [remaining, setRemaining] = useState({
		seconds: 0,
		minutes: 0,
		hours: 0,
		days: 0,
	});

	if (suspension == null || suspension.duration <= 0 || isDismissed) {
		return null;
	}

	return (
		<CountDown
			key="suspension-header"
			duration={suspension.duration}
			onExpired={() => {
				setIsDismissed(true);
			}}
			onUpdate={(reminder) => {
				const days = Math.floor(reminder / (3600 * 24));
				const hours = Math.floor((reminder % (3600 * 24)) / 3600);
				const minutes = Math.floor((reminder % 3600) / 60);
				const seconds = Math.floor(reminder % 60);

				setRemaining({ seconds, minutes, hours, days });
			}}
			updateOnFirstRender
			{...props}
		>
			<Message>
				<p>
					{t("is_suspended")}: {t(`type.${suspension.code}`)}.
				</p>
				<p>{t("suspension_remaining", remaining)}</p>
			</Message>
		</CountDown>
	);
}
